export function getTypeField(tipo: string) {
  switch (tipo) {
    case "integer":
      return "number";
    case "bigint":
      return "number";
    case "boolean":
      return "boolean";
    default:
      return "text";
  }
}