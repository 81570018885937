import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import background from "../../assets/background.jpg";
import DesenvolvidoPor from "../../components/DesenvolvidoPor";

export function Termo() {
  const history = useHistory();
  const theme = useTheme();

  function handleTermoAcceptance(accepted: boolean) {
    if (accepted) {
      localStorage.setItem("termo", "aceito");
      history.push("/dados-pessoais");
    } else {
      history.push("/login");
    }
  }

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "20px",
          position: "relative",
          paddingTop: "60px",
        }}
      >
        <Box
          sx={{
            background: "#FFFFFF",
            height: "auto",
            display: "flex",
            padding: "20px",
            flexDirection: "column",
            borderRadius: "3px",
            width: "90%",
            "@media (min-width: 1200px)": {
              width: "50%",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            gutterBottom
            textAlign={"center"}
          >
            Declaração de Veracidade das Informações Prestadas
          </Typography>
          <Divider
            sx={{
              margin: "0.6rem 0",
            }}
          ></Divider>
          <Box
            sx={{
              width: "100%",
              background: "#EBEBEB",
              height: "auto",
              display: "flex",
              padding: theme.spacing(2),
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1.2rem",
                textAlign: "center",
                marginBottom: theme.spacing(2),
              }}
              gutterBottom
            >
              Declaro que as informações fornecidas à Codevasf e Topocart são verdadeiras e atuais, e reconheço as
              consequências legais de fornecer informações falsas ou imprecisas, conforme previsto no Código Penal
              Brasileiro*. Comprometo-me a alertar a Codevasf e Topocart sobre qualquer inconsistência nas informações e
              assumo a responsabilidade pela veracidade dos dados fornecidos, reconhecendo as penalidades legais por
              qualquer violação desse compromisso.
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              CÓDIGO PENAL
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1.2rem",
                textAlign: "center",
              }}
            >
              Art. 171 - Obter, para si ou para outrem, vantagem ilícita, em prejuízo alheio, induzindo ou mantendo
              alguém em erro, mediante artifício, ardil, ou qualquer outro meio fraudulento
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1.2rem",
                textAlign: "center",
              }}
            >
              Art. 299 - Omitir, em documento público ou particular, declaração que dele devia constar, ou nele inserir
              ou fazer inserir declaração falsa ou diversa da que devia ser escrita, com o fim de prejudicar direito,
              criar obrigação ou alterar a verdade sobre fato juridicamente relevante.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "50px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => handleTermoAcceptance(false)}
            sx={{ borderRadius: "5px", marginRight: "10px", width: "150px" }}
          >
            Discordo
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleTermoAcceptance(true)}
            sx={{ borderRadius: "5px", width: "150px" }}
          >
            Concordo
          </Button>
        </Box>
        <DesenvolvidoPor />
      </Box>
    </>
  );
}
