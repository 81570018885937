import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { MdAddCircleOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import DesenvolvidoPor from "../../components/DesenvolvidoPor";
import background from "../../assets/background.jpg";
import { useEffect, useState } from "react";
import { Usuario } from "../../interfaces";
import { getAllUsers, putBlockUser, putLiberarUser, postEmail } from "../../services/api";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export default function Administracao() {
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState<Usuario>();
  const [users, setUsers] = useState<Usuario[]>([]);
  const [isLiberarUsuarioEnabled, setIsLiberarUsuarioEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchData();
    }
  }, [selectedUser]);

  const fetchData = async () => {
    try {
      const response = await getAllUsers();
      setUsers(response.data);
    } catch (error: any) {}
  };

  function selecionaUsuario(user: any) {
    setSelectedUser(user);
    setIsLiberarUsuarioEnabled(user.is_liberado === false && !user.is_admin);
  }

  function gerarSenha(tamanho: number): string {
    const caracteresPermitidos = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let senha = "";

    for (let i = 0; i < tamanho; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteresPermitidos.length);
      senha += caracteresPermitidos.charAt(indiceAleatorio);
    }

    return senha;
  }

  async function liberarUsuario() {
    try {
      if (!selectedUser) {
        return;
      }
      setIsLoading(true);

      if (selectedUser.password === null) {
        const password = gerarSenha(8);
        toast.success(`Enviando email para ${selectedUser.username}.`);
        await Promise.all([
          putLiberarUser(selectedUser.id, password),
          postEmail({
            destinatarios: [selectedUser.username],
            assunto: "Liberação de Acesso - Atendimento - CODEVASF",
            corpo: `Prezado usuário, o seu acesso à plataforma de atendimento da CODEVASF foi liberado e sua senha é <strong>${password}</strong>. Acesse https://atendimento.codevasf.topocart.dev.br para prosseguir na atualização cadastral de suas unidades parcelares.`,
          }),
        ]);
      } else {
        await putLiberarUser(selectedUser.id);
      }

      setIsLiberarUsuarioEnabled(false);
      fetchData();
      toast.success("Usuário liberado com sucesso!");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function bloquearUsuario() {
    try {
      if (selectedUser) {
        const response = await putBlockUser(selectedUser.id);

        if (response.status === 200) {
          setIsLiberarUsuarioEnabled(true);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        fetchData();
      }
    } catch (error: any) {
      toast.error(error.response.message);
    }
  }

  function handleRedirect() {
    history.push("/home");
  }

  function redirectLogin() {
    history.push("/login");
  }

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            width: "85vw",
            height: "85vh",
            display: "flex",
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              width: "70vw",
              height: "90%",
              alignSelf: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "bold",
                color: "#000000",
                marginTop: "2.5rem",
                alignSelf: "center",
                marginBottom: "2rem",
              }}
            >
              Lista de Usuários
            </Typography>
            <Divider
              sx={{
                borderBottom: "1px solid #A5A5A5",
              }}
            />
            <Box
              sx={{
                flexDirection: "column",
                "@media (max-width: 800px)": {
                  flexDirection: "column",
                },
                minHeight: "auto",
                height: "90%",
                marginTop: "2rem",
              }}
            >
              <Grid container spacing={2} sx={{ height: "95%" }}>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  sx={{
                    overflowY: "scroll",
                    height: "100%",
                    "@media (max-width: 600px)": {
                      height: "50%",
                    },
                  }}
                >
                  {users &&
                    users.map((user, index) => (
                      <Box
                        key={index}
                        onClick={() => selecionaUsuario(user)}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "4px",
                          marginRight: "10px",
                          backgroundColor: selectedUser && selectedUser.id === user.id ? "#D9D9D9" : "transparent",
                          ":hover": {
                            cursor: "pointer",
                            color: "#000000",
                            backgroundColor: "#D9D9D9",
                          },
                          ":active": {
                            backgroundColor: "#D9D9D9",
                          },
                          gap: "5px",
                          border: "1px solid #D9D9D9",
                        }}
                      >
                        <Typography sx={{ padding: "5px", fontSize: "16px", color: "#000000" }}>{user.nome}</Typography>

                        {user.is_admin && (
                          <Typography sx={{ padding: "5px", fontSize: "16px", color: "#000000" }}>
                            Administrador
                          </Typography>
                        )}

                        {!user.is_admin && user.is_liberado === true && (
                          <Typography sx={{ padding: "5px", fontSize: "16px", color: "#000000" }}>
                            Usuário - Liberado
                          </Typography>
                        )}

                        {!user.is_admin && user.is_liberado === false && (
                          <Typography sx={{ padding: "5px", fontSize: "16px", color: "#000000" }}>
                            Usuário - Não Liberado
                          </Typography>
                        )}
                      </Box>
                    ))}
                </Grid>
                <Grid item xs={12} sm={3} container justifyContent="flex-end">
                  <Box>
                    <Button
                      startIcon={<MdAddCircleOutline />}
                      variant="contained"
                      color="secondary"
                      sx={{
                        fontSize: "14px",
                        width: "100%",
                        height: "auto",
                        textTransform: "none",
                        marginBottom: "10px",
                        justifyContent: "flex-start",
                      }}
                      onClick={handleRedirect}
                    >
                      Adicionar usuário
                    </Button>
                    <Button
                      startIcon={<FaEdit />}
                      variant="contained"
                      color="secondary"
                      sx={{
                        fontSize: "14px",
                        width: "100%",
                        height: "auto",
                        textTransform: "none",
                        marginBottom: "10px",
                        justifyContent: "flex-start",
                      }}
                      onClick={handleRedirect}
                    >
                      Detalhes do usuário
                    </Button>

                    <Button
                      startIcon={<RiDeleteBin6Line />}
                      variant="contained"
                      sx={{
                        fontSize: "14px",
                        width: "100%",
                        height: "auto",
                        textTransform: "none",
                        background: "#FF0000",
                        justifyContent: "flex-start",
                      }}
                      onClick={handleRedirect}
                    >
                      Remover usuário
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "end",
                      width: "100%",
                      "@media (max-width: 600px)": {
                        height: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Button
                      startIcon={<FaCheckCircle />}
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: "17vw",
                        "@media (max-width: 600px)": {
                          width: "100%",
                        },
                        height: "auto",
                        textTransform: "none",
                        fontSize: "14px",
                        marginTop: "10px",
                        justifyContent: "flex-start",
                      }}
                      onClick={liberarUsuario}
                      disabled={!isLiberarUsuarioEnabled || (selectedUser && selectedUser.is_admin)}
                    >
                      {isLoading ? "Carregando..." : "Liberar usuário"}
                    </Button>
                    <Button
                      startIcon={<MdCancel />}
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: "17vw",
                        "@media (max-width: 600px)": {
                          width: "100%",
                        },
                        fontSize: "14px",
                        height: "auto",
                        textTransform: "none",
                        marginTop: "10px",
                        justifyContent: "flex-start",
                      }}
                      onClick={bloquearUsuario}
                      disabled={isLiberarUsuarioEnabled || (selectedUser && selectedUser.is_admin)}
                    >
                      Bloquear usuário
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "fixed",
            bottom: 0,
            marginBottom: "1.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85vw",
            }}
          >
            <DesenvolvidoPor />
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "14px",
                height: "auto",
                textTransform: "none",
                width: "15vw",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
              onClick={redirectLogin}
            >
              Sair
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
