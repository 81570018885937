import { Toaster } from "react-hot-toast";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Theme from "./providers/Theme";
import Login from "./pages/Login";
import Home from "./pages/Home/index";
import Cadastro from "./pages/Cadastro";
import Protocolo from "./pages/Protocolo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ListaUnidades from "./pages/ListaUnidades";
import Administracao from "./pages/Administracao";
import { EdicaoUnidade } from "./pages/EdicaoUnidade";
import { DadosPessoais } from "./pages/DadosPessoais";
import LocalizaUnidade from "./pages/LocalizaUnidade";
import { Termo } from "./pages/Termo";
import { CriacaoUnidade } from "./pages/CriacaoUnidade";

function App() {
  return (
    <Theme>
      <Toaster position="top-right" />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/lista-unidades">
              <ListaUnidades />
            </Route>
            <Route exact path="/cadastro">
              <Cadastro />
            </Route>
            <Route exact path="/protocolo">
              <Protocolo />
            </Route>
            <Route exact path="/administracao">
              <Administracao />
            </Route>
            <Route exact path="/edicao-unidade/:id">
              <EdicaoUnidade />
            </Route>
            <Route exact path="/criacao-unidade">
              <CriacaoUnidade />
            </Route>
            <Route exact path="/termo">
              <Termo />
            </Route>
            <Route exact path="/dados-pessoais">
              <DadosPessoais />
            </Route>
            <Route path="/localiza-unidade/:id?">
              <LocalizaUnidade />
            </Route>
            <Route path="*">
              <Redirect to={"/login"} />
            </Route>
          </Switch>
        </BrowserRouter>
      </LocalizationProvider>
    </Theme>
  );
}

export default App;
