import { Box, Button, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import background from "../../assets/background.jpg";
import DesenvolvidoPor from "../../components/DesenvolvidoPor";
import { UnidadeParcelar } from "../../interfaces";
import { getByUserByToken, getUnidadeParcelarByCpfCnpj } from "../../services/api";

export default function ListaUnidades() {
  const history = useHistory();
  const [dadosUnidadesParcelar, setDadosUnidadesParcelar] = useState<UnidadeParcelar[]>([]);

  useEffect(() => {
    localStorage.removeItem(`log`)

    if (localStorage.getItem("termo") !== "aceito") {
      history.push("/login");
    }

    const fetchData = async () => {
      try {
        const dadoPessoais = await getByUserByToken();
        const response = await getUnidadeParcelarByCpfCnpj(dadoPessoais.data.cpf_cnpj);
        setDadosUnidadesParcelar(response.data);
      } catch (error: any) {}
    };

    fetchData();
  }, []);

  function handleClickUnidadeParcelar(unidadeParcelar: UnidadeParcelar) {
    localStorage.setItem("edicao_unidadeParcelar", JSON.stringify(unidadeParcelar.id_unidade_parcelar))
    history.push(`/localiza-unidade`, { unidadeParcelar });
  }

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            width: "55%",
            height: "50%",
            background: "#FFFFFF",
            borderRadius: "5px",
            marginTop: "70px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            "@media (max-width: 1000px)": {
              width: "70%",
              height: "45%",
            },
            "@media (max-width: 600px)": {
              width: "90%",
              height: "60%",
            },
            "@media (max-width: 400px)": {
              width: "85%",
              height: "75%",
            },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            Selecione a unidade que deseja cadastrar
          </Typography>
          <Divider />
          <Typography
            sx={{
              fontSize: "16px",
              margin: "20px",
              textAlign: "justify",
            }}
          >
            Segundo nossa base de dados, as seguintes unidades parcelares se encontram registradas em seu nome,
            selecione uma delas para começar o cadastramento.
          </Typography>
          <Box
            sx={{
              flexDirection: "column",
              minHeight: "auto",
              overflowY: "scroll",
              height: "400px",
            }}
          >
            {dadosUnidadesParcelar &&
              dadosUnidadesParcelar.map((unidadeParcelar, index) => (
                <Box
                  key={index}
                  onClick={() => handleClickUnidadeParcelar(unidadeParcelar)}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "3px",
                    marginLeft: "1.3rem",
                    ":hover": {
                      cursor: "pointer",
                      color: "#515151",
                    },
                    marginRight: "1.3rem",
                    gap: "5px",
                  }}
                >
                  <Typography> {unidadeParcelar.identificador_up || "Unidade não identificada"}</Typography>
                  {unidadeParcelar.atualizada_em == null ? (
                    <Typography
                      sx={{
                        color: "#515151",
                        fontSize: "12px",
                      }}
                    >
                      Não atualizada
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#515151",
                        fontSize: "12px",
                      }}
                    >
                      Atualizada em:{" "}
                      {unidadeParcelar.atualizada_em
                        ? new Date(unidadeParcelar.atualizada_em).toLocaleDateString()
                        : "Não Atualizado"}
                    </Typography>
                  )}
                </Box>
              ))}
          </Box>

          <Typography
            sx={{
              fontSize: "16px",
              margin: "20px",
              textAlign: "justify",
            }}
          >
            Não encontrou a unidade parcelar que procurava? Notifique a CODEVASF da alteração:
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              alignSelf: "center",
              width: "236px",
              height: "33px",
            }}
            onClick={() => history.push(`/localiza-unidade`)}
          >
            Notificar nova unidade
          </Button>
        </Box>
        <DesenvolvidoPor />
      </Box>
    </>
  );
}
