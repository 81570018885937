import { Box, Button, Divider, MenuItem, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import cnpj from "validation-br/dist/cnpj";
import cpf from "validation-br/dist/cpf";
import background from "../../assets/background.jpg";
import logoTopocart from "../../assets/logo_topocart.png";
import { cadastrarUsuario, getAllDominios } from "../../services/api";
import { ufEstados } from "../../utils/ufs";

function Cadastro() {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [showAlternateForm, setShowAlternateForm] = useState(false);
  const [selectedDate, setSelectDate] = useState<Date | null>();
  const [dominios, setDominios] = useState<Dominio[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllDominios();
        setDominios(response.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  function handleChangeCpfCnpj(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === "cpf_cnpj") {
      if (cnpj(event.target.value)) {
        setShowAlternateForm(true);
      } else {
        setShowAlternateForm(false);
      }
    }
  }

  function handleChangeRequired(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const name = event.target.name;

    if (!value.trim()) {
      setError(name, {
        type: "manual",
        message: "Este campo é obrigatório",
      });
    } else {
      clearErrors(name);
    }
  }

  function handleDateChange(date: Date | null) {
    setSelectDate(date);
  }

  type Dominio = {
    dominio: any;
    tabela: string;
  };

  function validatorCpjCnpj(value: string) {
    const isCpfValid = cpf(value);
    const isCnpjValid = cnpj(value);

    if (!isCpfValid && !isCnpjValid) {
      setError("cpf_cnpj", {
        type: "manual",
        message: "CPF/CNPJ inválido",
      });
      setIsLoading(false);
      return;
    } else {
      clearErrors("cpf_cnpj");
    }
  }

  function getOptionsFromDominio(tabela: string) {
    const dominio = dominios?.find((item) => item.tabela === tabela);
    return dominio ? dominio.dominio : [];
  }

  async function onSubmit(data: any) {
    setIsLoading(true);

    validatorCpjCnpj(data.cpf_cnpj);

    data.numero = parseInt(data.numero);

    data.data_nascimento = selectedDate?.toISOString();

    const response = await cadastrarUsuario(data);

    if (response["atendimento"]) {
      history.push({
        pathname: "/protocolo",
        state: { userData: response["atendimento"] },
      });
    } else {
      toast.error(response);
    }
    setIsLoading(false);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: `url(${background})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                width: "85%",
                background: "#FFFFFF",
                height: "auto",
                borderRadius: "5px",
                marginTop: "70px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                gutterBottom
              >
                Cadastro Pessoal
              </Typography>
              <Divider sx={{ margin: "20px 0", marginTop: "30px" }} />

              <Typography variant="h5" gutterBottom>
                Dados Pessoais
              </Typography>
              <Divider sx={{ margin: "0.5rem 0" }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Nome/ Razão Social"
                  sx={{
                    width: ["100%", "100%", "100%", "67%"],
                  }}
                  {...register("nome_razao_social", { required: "Este campo é obrigatório para Nome/ Razão Social" })}
                  error={!!errors.nome_razao_social}
                  helperText={(errors.nome_razao_social as FieldError)?.message || ""}
                  onChange={handleChangeRequired}
                  data-testid="nome"
                />
                <TextField
                  type="text"
                  variant="filled"
                  label="CPF/CNPJ"
                  sx={{
                    width: ["100%", "100%", "100%", "30%"],
                  }}
                  {...register("cpf_cnpj", { required: "Este campo é obrigatório para CPF/CNPJ" })}
                  error={!!errors.cpf_cnpj}
                  helperText={(errors.cpf_cnpj as FieldError)?.message || ""}
                  inputProps={{ maxLength: 14 }}
                  onChangeCapture={handleChangeCpfCnpj}
                  onChange={(e) => validatorCpjCnpj(e.target.value)}
                  data-testid="cpfCnpj"
                />
              </Box>
              {showAlternateForm ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1rem",
                    marginBottom: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    type="text"
                    variant="filled"
                    label="Inscrição Estadual"
                    sx={{
                      width: ["100%", "100%", "30%", "32%"],
                    }}
                    {...register("inscricao_estadual", {
                      required: "Este campo é obrigatório para Inscrição Estadual",
                    })}
                    error={!!errors.inscricao_estadual}
                    helperText={(errors.inscricao_estadual as FieldError)?.message || ""}
                    onChange={handleChangeRequired}
                    data-testid="inscricaoEstadual"
                  />
                  <TextField
                    type="text"
                    variant="filled"
                    label="Inscrição Municipal"
                    sx={{
                      width: ["100%", "100%", "30%", "32%"],
                    }}
                    {...register("inscricao_municipal", {
                      required: "Este campo é obrigatório para Inscrição Municipal",
                    })}
                    error={!!errors.inscricao_municipal}
                    helperText={(errors.inscricao_municipal as FieldError)?.message || ""}
                    onChange={handleChangeRequired}
                  />
                  <TextField
                    select
                    variant="filled"
                    label="Ramo/Atividade"
                    sx={{
                      width: ["100%", "100%", "30%", "30%"],
                    }}
                    {...register("ramo_atividade", { required: "Este campo é obrigatório para Ramo/Atividade" })}
                    error={!!errors.ramo_atividade}
                    helperText={(errors.ramo_atividade as FieldError)?.message || ""}
                    onChange={handleChangeRequired}
                  >
                    {getOptionsFromDominio("ramo_atividade").map((option: any) => (
                      <MenuItem key={option.codigo} value={option.codigo}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextField
                      type="text"
                      variant="filled"
                      label="RG"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"],
                      }}
                      {...register("rg", { required: "Este campo é obrigatório para RG" })}
                      error={!!errors.rg}
                      helperText={(errors.rg as FieldError)?.message || ""}
                      onChange={handleChangeRequired}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="Orgão Emissor/UF"
                      sx={{
                        width: ["100%", "100%", "30%", "18%"],
                      }}
                      {...register("orgao_emissor_uf", { required: "Este campo é obrigatório para Orgão Emissor" })}
                      error={!!errors.orgao_emissor_uf}
                      helperText={(errors.orgao_emissor_uf as FieldError)?.message || ""}
                      onChange={handleChangeRequired}
                    />
                    <DatePicker
                      label="Data de Nascimento"
                      value={selectedDate}
                      disableFuture
                      sx={{
                        width: ["100%", "100%", "30%", "18%"],
                        backgroundColor: "#F0F0F0",
                      }}
                      slotProps={{
                        layout: {},
                      }}
                      format="DD/MM/YYYY"
                      onChange={handleDateChange}
                    />

                    <TextField
                      select
                      variant="filled"
                      label="Sexo"
                      sx={{
                        width: ["100%", "100%", "30%", "18%"],
                      }}
                      {...register("sexo", { required: "Este campo é obrigatório para Sexo" })}
                      error={!!errors.sexo}
                      helperText={(errors.sexo as FieldError)?.message || ""}
                      onChange={handleChangeRequired}
                    >
                      {getOptionsFromDominio("sexo").map((option: any) => (
                        <MenuItem key={option.codigo} value={option.codigo}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      variant="filled"
                      label="Estado Cívil"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"],
                      }}
                      {...register("estado_civil", { required: "Este campo é obrigatório para Estado Civil" })}
                      error={!!errors.estado_civil}
                      helperText={(errors.estado_civil as FieldError)?.message || ""}
                      onChange={handleChangeRequired}
                    >
                      {getOptionsFromDominio("estado_civil").map((option: any) => (
                        <MenuItem key={option.codigo} value={option.codigo}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <TextField
                    select
                    variant="filled"
                    label="Escolaridade"
                    sx={{
                      width: ["100%", "100%", "100%", "18%"],
                    }}
                    {...register("escolaridade", { required: "Este campo é obrigatório para Escolaridade" })}
                    error={!!errors.escolaridade}
                    helperText={(errors.escolaridade as FieldError)?.message || ""}
                    onChange={handleChangeRequired}
                  >
                    {getOptionsFromDominio("escolaridade").map((option: any) => (
                      <MenuItem key={option.codigo} value={option.codigo}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}

              <Divider sx={{ margin: "0.5rem 0" }} />
              <Typography variant="h5" gutterBottom>
                Endereço de Correspondência
              </Typography>
              <Divider sx={{ margin: "0.5rem 0" }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  select
                  variant="filled"
                  label="Tipo de Endereço"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"],
                  }}
                  {...register("tipo_endereco", { required: "Este campo é obrigatório para Tipo de Endereço" })}
                  error={!!errors.tipo_endereco}
                  helperText={(errors.tipo_endereco as FieldError)?.message || ""}
                  onChange={handleChangeRequired}
                >
                  {getOptionsFromDominio("tipo_endereco").map((option: any) => (
                    <MenuItem key={option.codigo} value={option.codigo}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="text"
                  variant="filled"
                  label="Logradouro"
                  sx={{
                    width: ["100%", "100%", "100%", "80%"],
                  }}
                  {...register("logradouro", { required: "Este campo é obrigatório para Logradouro" })}
                  error={!!errors.logradouro}
                  helperText={(errors.logradouro as FieldError)?.message || ""}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChangeRequired}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  type="number"
                  variant="filled"
                  label="Número/Complemento"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"],
                  }}
                  {...register("numero", { required: "Este campo é obrigatório para Número/Complemento" })}
                  error={!!errors.numero}
                  helperText={(errors.numero as FieldError)?.message || ""}
                  onChange={handleChangeRequired}
                />
                <TextField
                  type="text"
                  variant="filled"
                  label="Bairro"
                  sx={{
                    width: ["100%", "100%", "100%", "28%"],
                  }}
                  {...register("bairro", { required: "Este campo é obrigatório para Bairro" })}
                  error={!!errors.bairro}
                  helperText={(errors.bairro as FieldError)?.message || ""}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChangeRequired}
                />
                <TextField
                  type="text"
                  variant="filled"
                  label="Cidade"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"],
                  }}
                  {...register("cidade", { required: "Este campo é obrigatório para Cidade" })}
                  error={!!errors.cidade}
                  helperText={(errors.cidade as FieldError)?.message || ""}
                  inputProps={{ maxLength: 200 }}
                  onChange={handleChangeRequired}
                />
                <TextField
                  select
                  variant="filled"
                  label="UF"
                  sx={{
                    width: ["100%", "100%", "100%", "10%"],
                  }}
                  {...register("uf")}
                  error={!!errors.uf}
                  helperText={(errors.uf as FieldError)?.message || ""}
                  inputProps={{ maxLength: 200 }}
                  onChange={handleChangeRequired}
                >
                  {ufEstados.map((uf: string, i: any) => (
                    <MenuItem key={i} value={uf}>
                      {uf}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="text"
                  variant="filled"
                  label="CEP"
                  sx={{
                    width: ["100%", "100%", "100%", "20%"],
                  }}
                  {...register("cep", { required: "Este campo é obrigatório para CEP" })}
                  error={!!errors.cep}
                  helperText={(errors.cep as FieldError)?.message || ""}
                  inputProps={{ maxLength: 8 }}
                  onChange={handleChangeRequired}
                />
              </Box>

              <Typography variant="h5" gutterBottom>
                Contato
              </Typography>
              <Divider sx={{ margin: "0.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="DDD"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"],
                  }}
                  {...register("ddd_celular", { required: "Este campo é obrigatório para DDD" })}
                  error={!!errors.ddd_celular}
                  helperText={(errors.ddd_celular as FieldError)?.message || ""}
                  inputProps={{ maxLength: 2 }}
                  onChange={handleChangeRequired}
                />
                <TextField
                  type="text"
                  variant="filled"
                  label="Telefone Celular"
                  sx={{
                    width: ["100%", "100%", "100%", "25%"],
                  }}
                  {...register("telefone_celular", { required: "Este campo é obrigatório para Telefone Celular" })}
                  error={!!errors.telefone_celular}
                  helperText={(errors.telefone_celular as FieldError)?.message || ""}
                  inputProps={{ maxLength: 9 }}
                  onChange={handleChangeRequired}
                />
                <TextField
                  type="text"
                  variant="filled"
                  label="E-mail"
                  sx={{
                    width: ["100%", "100%", "100%", "53%"],
                  }}
                  {...register("email", { required: "Este campo é obrigatório para E-mail" })}
                  error={!!errors.email}
                  helperText={(errors.email as FieldError)?.message || ""}
                  onChange={handleChangeRequired}
                />
              </Box>
            </Box>
            <Box sx={{ width: "85%", display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  padding: "0.8rem",
                  width: "236px",
                }}
                disabled={isLoading}
              >
                {!isLoading ? "Finalizar" : "Finalizando..."}
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" sx={{ color: "white" }} gutterBottom>
                Desenvolvido por
              </Typography>

              <Box component={"img"} alt="LogoTopocart" src={logoTopocart} sx={{ height: 30, paddingLeft: "0.5rem" }} />
            </Box>
          </Box>
        </LocalizationProvider>
      </form>
    </>
  );
}

export default Cadastro;
