import { Box, Button, Divider, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import background from "../../assets/background.jpg";
import DesenvolvidoPor from "../../components/DesenvolvidoPor";
import { DadosUsuarioCadastrado } from "../../interfaces";

export default function Protocolo() {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { userData: DadosUsuarioCadastrado };

  console.log(state.userData);
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "100px",
        }}
      >
        <Box
          sx={{
            width: "40%",
            maxWidth: "1900px",
            background: "#FFFFFF",
            maxHeight: "55vh",
            minWidth: "330px",
            height: "60%",
            borderRadius: "5px",
            marginTop: "70px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            "@media (max-width: 1200px)": {
              width: "90%",
              height: "90%",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "2rem",
              color: "#000000",
              lineHeight: "1.2",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Obrigado
          </Typography>
          <Divider sx={{ margin: "0.5rem 0" }} />

          <Typography
            sx={{
              color: "#000000",
              lineHeight: "1.2",
              fontSize: "26px",
              alignSelf: "center",
              maxWidth: "85%",
              padding: "50px",
              textAlign: "center",
              strong: {
                fontWeight: "bold",
              },
            }}
          >
            Nossa equipe irá analisar as informações e, caso aprovado, enviara uma senha de acesso para o email
            cadastrado.
          </Typography>
          {/* <Typography
            sx={{
              color: "#000000",
              lineHeight: "1.2",
              fontSize: "26px",
              alignSelf: "center",
              maxWidth: "85%",
              padding: "50px",
              textAlign: "center",
              strong: {
                fontWeight: "bold",
              },
            }}
          >
            Caso surjam dúvidas ou necessite complementar a documentação, favor enviar um email para{" "}
            <strong>email@email.com.br</strong> e informar o número do protocolo{" "}
            <strong> #{state.userData.numero_protocolo || ""} </strong>
          </Typography> */}
        </Box>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push("/")}
          sx={{
            marginTop: "4rem",
            padding: "0.8rem",
            width: "236px",
          }}
        >
          Voltar à página inicial
        </Button>

        <DesenvolvidoPor />
      </Box>
    </div>
  );
}
