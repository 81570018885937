import { IlogInsert, IlogUpdate } from "../interfaces/logs.interface";

/**
 * Registra uma atualização no log de mudanças.
 *
 * @param {IlogUpdate} logEntry - O registro de atualização a ser adicionado ao log.
 * @returns {void}
 *
 * @example
 * const updateEntry = {
 *   nome_tabela: "tabela",
 *   id_origem: "123",
 *   nome_campo: "campo",
 *   tipo_campo: "text",
 *   valor_campo: "novo_valor"
 * };
 *
 * logUpdate(updateEntry);
 */

export function logUpdate(logEntry: IlogUpdate): void {
  if (!localStorage.getItem("log")) {
    localStorage.setItem("log", JSON.stringify({ inserts: [], updates: [], deletes: [] }));
  }

  if (!localStorage.getItem("commit_no")) {
    localStorage.setItem("commit_no", "1");
  }

  const log = JSON.parse(localStorage.getItem("log")!);
  logEntry["numero_commit"] = Number(JSON.parse(localStorage.getItem("commit_no")!));

  function verifyIfEntryExists(logEntry: any) {
    return log.updates.findIndex((e: any) => {
      return (
        e.nome_tabela === logEntry.nome_tabela &&
        e.id_origem === logEntry.id_origem &&
        e.nome_campo === logEntry.nome_campo
      );
    });
  }

  function updateEntry(index: number, logEntry: any) {
    logEntry["id"] = index + 1;
    log.updates[index] = logEntry;
    return null;
  }

  function insertEntry(logEntry: any) {
    logEntry["id"] = log.updates.length;
    log.updates.push(logEntry);
    return null;
  }

  if (verifyIfEntryExists(logEntry) !== -1) {
    // console.log('Atualizando entrada no log.updates: ', log)
    updateEntry(verifyIfEntryExists(logEntry), logEntry);
  } else {
    // console.log('Inserindo nova entrada no log.updates: ', log)
    insertEntry(logEntry);
  }

  localStorage.setItem("log", JSON.stringify(log));
}

/**
 * Registra uma inserção no log de mudanças.
 *
 * @param {IlogInsert} logEntry - O registro de inserção a ser adicionado ao log.
 * @returns {void}
 *
 * @example
 * const insertEntry = {
 *   nome_tabela: "tabela",
 *   fid: "456"
 * };
 *
 * logInsert(insertEntry);
 */

export function logInsert(logEntry: IlogInsert): void {
  if (!localStorage.getItem("log")) {
    localStorage.setItem("log", JSON.stringify({ inserts: [], updates: [], deletes: [] }));
  }

  const log = JSON.parse(localStorage.getItem("log")!);

  function verifyIfEntryExists(logEntry: any) {
    return log.inserts.findIndex((e: any) => {
      return e.nome_tabela === logEntry.nome_tabela && e.fid === logEntry.fid;
    });
  }

  function entryInsert(index: number, logEntry: any) {
    logEntry["id"] = index + 1;
    log.inserts[index] = logEntry;
    return null;
  }

  function insertEntry(logEntry: any) {
    logEntry["id"] = log.inserts.length;
    log.inserts.push(logEntry);
    return null;
  }

  if (verifyIfEntryExists(logEntry) !== -1) {
    entryInsert(verifyIfEntryExists(logEntry), logEntry);
  } else {
    insertEntry(logEntry);
  }
  localStorage.setItem("log", JSON.stringify(log));
}
