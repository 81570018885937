import { Box, Button, Divider, MenuItem, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { FaPlusCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import cnpj from "validation-br/dist/cnpj";
import cpf from "validation-br/dist/cpf";
import { z } from "zod";
import background from "../../assets/background.jpg";
import logoTopocart from "../../assets/logo_topocart.png";
import { getAllDominios, getByUserByToken, putDadosPessoaisById } from "../../services/api";
import { ufEstados } from "../../utils/ufs";

export function DadosPessoais() {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [forms, setForms] = useState([{ id: 0 }]);
  const [dadosEditados, setDadosEditados] = useState({});
  const history = useHistory();
  const [showAlternateForm, setShowAlternateForm] = useState(false);
  const [dominios, setDominios] = useState<Dominio[]>([]);
  const [dadosPessoais, setDadosPessoas] = useState<any>();

  useEffect(() => {
    if (localStorage.getItem("termo") !== "aceito") {
      history.push("/login");
    }

    const fetchData = async () => {
      try {
        const response = await getAllDominios();

        const dadosPessoais = await getByUserByToken();

        setDadosPessoas(dadosPessoais.data);
        setDominios(response.data);

        if (cnpj(dadosPessoais.data.cpf_cnpj)) {
          setShowAlternateForm(true);
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  function handleChangeCpfCnpj(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === "cpf_cnpj") {
      if (cnpj(event.target.value)) {
        setShowAlternateForm(true);
      } else {
        setShowAlternateForm(false);
      }
    }
  }

  type Dominio = {
    dominio: any;
    tabela: string;
  };

  function validatorCpjCnpj(value: string, campo: string) {
    const isCpfValid = cpf(value);
    const isCnpjValid = cnpj(value);

    if (!isCpfValid && !isCnpjValid) {
      setError(campo, {
        type: "manual",
        message: "CPF/CNPJ inválido",
      });
      setIsLoading(false);
      return;
    } else {
      clearErrors(campo);
    }
  }

  function validatorEmail(value: string, campo: string) {
    try {
      const schema = z.object({
        email: z.string().email(),
      });
      schema.parse({ email: value });
      clearErrors(campo);
    } catch (error) {
      setError(campo, {
        type: "manual",
        message: "E-mail inválido",
      });
      setIsLoading(false);
    }
  }

  function validatorTelefone(value: string, campo: string) {
    try {
      const schema = z.object({
        telefone_celular: z.string().regex(/^\d{9}$/),
      });
      schema.parse({ telefone_celular: value });
      clearErrors(campo);
    } catch (error) {
      setError(campo, {
        type: "manual",
        message: "Telefone inválido",
      });
      setIsLoading(false);
    }
  }

  const adicionarNovoFormulario = () => {
    const novoFormulario = { id: forms.length + 1 };
    setForms([...forms, novoFormulario]);
  };

  function getOptionsFromDominio(tabela: string) {
    const dominio = dominios?.find((item) => item.tabela === tabela);
    return dominio ? dominio.dominio : [];
  }

  function separarCampos(jsonOriginal: any) {
    const arrayDadosVinculos: any[] = [];
    const jsonFinal: any = {};
    let index = 0;
    let vinculadoAtual: any = {};

    for (const chave in jsonOriginal) {
      const indiceMatch = chave.match(/_(\d+)$/);

      if (indiceMatch) {
        const nomeCampo = chave.replace(/_\d+$/, "");
        const indice = parseInt(indiceMatch[1]);

        if (indice !== index) {
          arrayDadosVinculos.push(vinculadoAtual);
          vinculadoAtual = {};
          index = indice;
        }

        vinculadoAtual[nomeCampo] = jsonOriginal[chave];
        delete jsonOriginal[chave];
      }
    }
    arrayDadosVinculos.push(vinculadoAtual);

    const dependentesFiltrados = arrayDadosVinculos.filter((obj) => Object.keys(obj).length !== 0);

    if (!showAlternateForm && dependentesFiltrados.length !== 0) {
      jsonFinal["dependentes"] = dependentesFiltrados;
    } else if (showAlternateForm && dependentesFiltrados.length > 0) {
      jsonFinal["socios"] = dependentesFiltrados;
    }

    jsonFinal["dadosPessoais"] = jsonOriginal;

    return jsonFinal;
  }

  async function onSubmit() {
    try {
      const dados = separarCampos(dadosEditados);
      await putDadosPessoaisById(dadosPessoais.id_pessoa, dados);
      history.push("/lista-unidades");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleChange(valor: any, campo: string, isDate?: boolean) {
    if (isDate) {
      setDadosEditados({
        ...dadosEditados,
        [campo]: valor.toISOString(),
      });
      return;
    }
    setDadosEditados({
      ...dadosEditados,
      [campo]: valor,
    });
  }

  return (
    <>
      {dadosPessoais && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box
              sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url(${background})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                paddingBottom: "20px",
              }}
            >
              <Box
                sx={{
                  width: "85%",
                  background: "#FFFFFF",
                  height: "auto",
                  borderRadius: "5px",
                  marginTop: "70px",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  gutterBottom
                >
                  Cadastro Pessoal
                </Typography>
                <Divider sx={{ margin: "20px 0", marginTop: "30px" }} />

                <Typography variant="h5" gutterBottom>
                  Dados Pessoais
                </Typography>
                <Divider sx={{ margin: "0.5rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1rem",
                    marginBottom: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    type="text"
                    variant="filled"
                    label="Nome/ Razão Social"
                    sx={{
                      width: ["100%", "100%", "100%", "67%"],
                    }}
                    disabled={!!dadosPessoais?.nome_razao_social}
                    defaultValue={dadosPessoais && dadosPessoais.nome_razao_social}
                    {...register("nome_razao_social")}
                    error={!!errors.nome_razao_social}
                    helperText={(errors.nome_razao_social as FieldError)?.message || ""}
                  />
                  <TextField
                    type="text"
                    variant="filled"
                    label="CPF/CNPJ"
                    sx={{
                      width: ["100%", "100%", "100%", "30%"],
                    }}
                    defaultValue={dadosPessoais?.cpf_cnpj}
                    disabled={!!dadosPessoais?.cpf_cnpj}
                    {...register("cpf_cnpj")}
                    inputProps={{ maxLength: 14 }}
                    onChangeCapture={handleChangeCpfCnpj}
                  />
                </Box>
                {showAlternateForm ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextField
                      type="text"
                      variant="filled"
                      label="Inscrição Estadual"
                      sx={{
                        width: ["100%", "100%", "30%", "32%"],
                      }}
                      defaultValue={dadosPessoais?.inscricao_estadual}
                      {...register("inscricao_estadual")}
                      error={!!errors.inscricao_estadual}
                      helperText={(errors.inscricao_estadual as FieldError)?.message || ""}
                      onChange={(e) => handleChange(e.target.value, "inscricao_estadual")}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="Inscrição Municipal"
                      sx={{
                        width: ["100%", "100%", "30%", "32%"],
                      }}
                      defaultValue={dadosPessoais?.inscricao_municipal}
                      {...register("inscricao_municipal")}
                      error={!!errors.inscricao_municipal}
                      helperText={(errors.inscricao_municipal as FieldError)?.message || ""}
                      onChange={(e) => handleChange(e.target.value, "inscricao_municipal")}
                    />
                    <TextField
                      select
                      variant="filled"
                      label="Ramo/Atividade"
                      sx={{
                        width: ["100%", "100%", "30%", "30%"],
                      }}
                      defaultValue={dadosPessoais?.ramo_atividade}
                      {...register("ramo_atividade")}
                      error={!!errors.ramo_atividade}
                      helperText={(errors.ramo_atividade as FieldError)?.message || ""}
                      onChange={(e) => handleChange(e.target.value, "ramo_atividade")}
                    >
                      {getOptionsFromDominio("ramo_atividade").map((option: any, i: any) => (
                        <MenuItem key={option.id + i} value={option.id}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "1rem",
                        marginBottom: "1rem",
                        flexWrap: "wrap",
                      }}
                    >
                      <TextField
                        type="text"
                        variant="filled"
                        label="RG"
                        sx={{
                          width: ["100%", "100%", "100%", "18%"],
                        }}
                        defaultValue={dadosPessoais?.rg}
                        {...register("rg")}
                        error={!!errors.rg}
                        helperText={(errors.rg as FieldError)?.message || ""}
                        onChange={(e) => handleChange(e.target.value, "rg")}
                      />
                      <TextField
                        type="text"
                        variant="filled"
                        label="Orgão Emissor/UF"
                        sx={{
                          width: ["100%", "100%", "30%", "18%"],
                        }}
                        defaultValue={dadosPessoais?.orgao_emissor_uf}
                        {...register("orgao_emissor_uf")}
                        error={!!errors.orgao_emissor_uf}
                        helperText={(errors.orgao_emissor_uf as FieldError)?.message || ""}
                        onChange={(e) => handleChange(e.target.value, "orgao_emissor_uf")}
                      />
                      <DatePicker
                        label="Data de Nascimento"
                        disableFuture
                        sx={{
                          width: ["100%", "100%", "30%", "18%"],
                          backgroundColor: "#F0F0F0",
                        }}
                        // @ts-ignore
                        defaultValue={moment(dadosPessoais?.data_nascimento)}
                        slotProps={{
                          layout: {},
                        }}
                        format="DD/MM/YYYY"
                        onChange={(e) => handleChange(e, "data_nascimento", true)}
                      />

                      <TextField
                        select
                        variant="filled"
                        label="Sexo"
                        sx={{
                          width: ["100%", "100%", "30%", "18%"],
                        }}
                        defaultValue={dadosPessoais?.sexo}
                        {...register("sexo")}
                        error={!!errors.sexo}
                        helperText={(errors.sexo as FieldError)?.message || ""}
                        onChange={(e) => handleChange(e.target.value, "sexo")}
                      >
                        {getOptionsFromDominio("sexo").map((option: any, i: any) => (
                          <MenuItem key={option.id + i} value={option.id}>
                            {option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        variant="filled"
                        label="Estado Cívil"
                        sx={{
                          width: ["100%", "100%", "100%", "18%"],
                        }}
                        defaultValue={dadosPessoais?.estado_civil}
                        {...register("estado_civil")}
                        error={!!errors.estado_civil}
                        helperText={(errors.estado_civil as FieldError)?.message || ""}
                        onChange={(e) => handleChange(e.target.value, "estado_civil")}
                      >
                        {getOptionsFromDominio("estado_civil").map((option: any, i: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <TextField
                      select
                      variant="filled"
                      label="Escolaridade"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"],
                      }}
                      defaultValue={dadosPessoais?.escolaridade}
                      {...register("escolaridade")}
                      error={!!errors.escolaridade}
                      helperText={(errors.escolaridade as FieldError)?.message || ""}
                      onChange={(e) => handleChange(e.target.value, "escolaridade")}
                    >
                      {getOptionsFromDominio("escolaridade").map((option: any, i: any) => (
                        <MenuItem key={option.id + i} value={option.id}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}

                <Divider sx={{ margin: "0.5rem 0" }} />
                <Typography variant="h5" gutterBottom>
                  Endereço de Correspondência
                </Typography>
                <Divider sx={{ margin: "0.5rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1rem",
                    marginBottom: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    select
                    variant="filled"
                    label="Tipo de Endereço"
                    sx={{
                      width: ["100%", "100%", "100%", "18%"],
                    }}
                    defaultValue={dadosPessoais?.tipo_endereco}
                    {...register("tipo_endereco")}
                    error={!!errors.tipo_endereco}
                    helperText={(errors.tipo_endereco as FieldError)?.message || ""}
                    onChange={(e) => handleChange(e.target.value, "tipo_endereco")}
                  >
                    {getOptionsFromDominio("tipo_endereco").map((option: any, i: any) => (
                      <MenuItem key={option.id + i} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Logradouro"
                    sx={{
                      width: ["100%", "100%", "100%", "80%"],
                    }}
                    defaultValue={dadosPessoais?.logradouro}
                    {...register("logradouro")}
                    error={!!errors.logradouro}
                    helperText={(errors.logradouro as FieldError)?.message || ""}
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => handleChange(e.target.value, "logradouro")}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1rem",
                    marginBottom: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    type="number"
                    variant="filled"
                    label="Número/Complemento"
                    sx={{
                      width: ["100%", "100%", "100%", "18%"],
                    }}
                    defaultValue={dadosPessoais?.numero}
                    {...register("numero")}
                    error={!!errors.numero}
                    helperText={(errors.numero as FieldError)?.message || ""}
                    onChange={(e) => handleChange(Number(e.target.value), "numero")}
                  />
                  <TextField
                    type="text"
                    variant="filled"
                    label="Bairro"
                    sx={{
                      width: ["100%", "100%", "100%", "28%"],
                    }}
                    defaultValue={dadosPessoais?.bairro}
                    {...register("bairro")}
                    error={!!errors.bairro}
                    helperText={(errors.bairro as FieldError)?.message || ""}
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => handleChange(e.target.value, "bairro")}
                  />
                  <TextField
                    type="text"
                    variant="filled"
                    label="Cidade"
                    sx={{
                      width: ["100%", "100%", "100%", "18%"],
                    }}
                    defaultValue={dadosPessoais?.cidade}
                    {...register("cidade")}
                    error={!!errors.cidade}
                    helperText={(errors.cidade as FieldError)?.message || ""}
                    inputProps={{ maxLength: 200 }}
                    onChange={(e) => handleChange(e.target.value, "cidade")}
                  />
                  <TextField
                    select
                    variant="filled"
                    label="UF"
                    sx={{
                      width: ["100%", "100%", "100%", "10%"],
                    }}
                    defaultValue={dadosPessoais?.uf}
                    {...register("uf")}
                    error={!!errors.uf}
                    helperText={(errors.uf as FieldError)?.message || ""}
                    inputProps={{ maxLength: 200 }}
                    onChange={(e) => handleChange(e.target.value, "uf")}
                  >
                    {ufEstados.map((uf: string, i: any) => (
                      <MenuItem key={i} value={uf}>
                        {uf}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    type="text"
                    variant="filled"
                    label="CEP"
                    sx={{
                      width: ["100%", "100%", "100%", "20%"],
                    }}
                    defaultValue={dadosPessoais?.cep}
                    {...register("cep")}
                    error={!!errors.cep}
                    helperText={(errors.cep as FieldError)?.message || ""}
                    inputProps={{ maxLength: 8 }}
                    onChange={(e) => handleChange(e.target.value, "cep")}
                  />
                </Box>

                <Typography variant="h5" gutterBottom>
                  Contato
                </Typography>
                <Divider sx={{ margin: "0.5rem 0" }} />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1rem",
                    marginBottom: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    type="text"
                    variant="filled"
                    label="DDD"
                    sx={{
                      width: ["100%", "100%", "100%", "18%"],
                    }}
                    defaultValue={dadosPessoais?.ddd_celular}
                    {...register("ddd_celular")}
                    error={!!errors.ddd_celular}
                    helperText={(errors.ddd_celular as FieldError)?.message || ""}
                    inputProps={{ maxLength: 2 }}
                    onChange={(e) => handleChange(e.target.value, "ddd_celular")}
                  />
                  <TextField
                    type="text"
                    variant="filled"
                    label="Telefone Celular"
                    sx={{
                      width: ["100%", "100%", "100%", "25%"],
                    }}
                    defaultValue={dadosPessoais?.telefone_celular}
                    {...register("telefone_celular")}
                    error={!!errors.telefone_celular}
                    helperText={(errors.telefone_celular as FieldError)?.message || ""}
                    inputProps={{ maxLength: 9 }}
                    onChange={(e) => {
                      handleChange(e.target.value, "telefone_celular");
                      validatorTelefone(e.target.value, "telefone_celular");
                    }}
                  />
                  <TextField
                    type="text"
                    variant="filled"
                    label="E-mail"
                    sx={{
                      width: ["100%", "100%", "100%", "53%"],
                    }}
                    defaultValue={dadosPessoais?.email}
                    {...register("email")}
                    error={!!errors.email}
                    helperText={(errors.email as FieldError)?.message || ""}
                    onChange={(e) => handleChange(e.target.value, "email")}
                  />
                </Box>
                <Divider sx={{ margin: "0.5rem 0" }} />
                {forms.map((form, index) => (
                  <div key={form.id}>
                    {showAlternateForm && form.id > 0 ? (
                      <div>
                        <Typography variant="h5" gutterBottom>
                          Contatos e Sócios
                        </Typography>
                        <Divider sx={{ margin: "0.5rem 0" }} />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: "1rem",
                            marginBottom: "1rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <TextField
                            select
                            variant="filled"
                            label="Relação com a propriedade"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`tipo_relacao_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`tipo_relacao_${index}`]}
                            helperText={(errors[`tipo_relacao_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => handleChange(e.target.value, `tipo_relacao_${index}`)}
                          >
                            {getOptionsFromDominio("tipo_relacao").map((option: any, i: any) => (
                              <MenuItem key={option.id + i} value={option.id}>
                                {option.descricao}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            type="text"
                            variant="filled"
                            label="Nome"
                            sx={{
                              width: ["100%", "100%", "100%", "79.5%"],
                            }}
                            {...register(`nome_razao_social_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`nome_razao_social_${index}`]}
                            helperText={(errors[`nome_razao_social_${index}`] as FieldError)?.message || ""}
                            inputProps={{ maxLength: 100 }}
                            onChange={(e) => handleChange(e.target.value, `nome_razao_social_${index}`)}
                          />
                          <TextField
                            type="text"
                            variant="filled"
                            label="CPF/CNPJ"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`cpf_cnpj_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`cpf_cnpj_${index}`]}
                            helperText={(errors[`cpf_cnpj_${index}`] as FieldError)?.message || ""}
                            inputProps={{ maxLength: 14 }}
                            onChangeCapture={handleChangeCpfCnpj}
                            onChange={(e) => {
                              validatorCpjCnpj(e.target.value, `cpf_cnpj_${index}`);
                              handleChange(e.target.value, `cpf_cnpj_${index}`);
                            }}
                          />
                          <TextField
                            type="text"
                            variant="filled"
                            label="RG"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`rg_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`rg_${index}`]}
                            helperText={(errors[`rg_${index}`] as FieldError)?.message || ""}
                            inputProps={{ maxLength: 20 }}
                            onChange={(e) => handleChange(e.target.value, `rg_${index}`)}
                          />
                          <TextField
                            type="text"
                            variant="filled"
                            label="Orgão Emissor/UF"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`orgao_emissor_uf_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`orgao_emissor_uf_${index}`]}
                            helperText={(errors[`orgao_emissor_uf_${index}`] as FieldError)?.message || ""}
                            inputProps={{ maxLength: 20 }}
                            onChange={(e) => handleChange(e.target.value, `orgao_emissor_uf_${index}`)}
                          />
                          <DatePicker
                            label="Data de Nascimento"
                            disableFuture
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                              backgroundColor: "#F0F0F0",
                            }}
                            slotProps={{
                              layout: {},
                            }}
                            format="DD/MM/YYYY"
                            onChange={(e) => handleChange(e, `data_nascimento_${index}`, true)}
                          />
                          <TextField
                            select
                            variant="filled"
                            label="Sexo"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`sexo_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`sexo_${index}`]}
                            helperText={(errors[`sexo_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => handleChange(e.target.value, `sexo_${index}`)}
                          >
                            {getOptionsFromDominio("sexo").map((option: any, i: any) => (
                              <MenuItem key={option.id + i} value={option.id}>
                                {option.descricao}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            select
                            variant="filled"
                            label="Estado Cívil"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`estado_civil_${index}`)}
                            error={!!errors[`estado_civil_${index}`]}
                            helperText={(errors[`estado_civil_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => handleChange(e.target.value, `estado_civil_${index}`)}
                          >
                            {getOptionsFromDominio("estado_civil").map((option: any, i: any) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.descricao}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            select
                            variant="filled"
                            label="Escolaridade"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`escolaridade_${index}`)}
                            error={!!errors[`escolaridade_${index}`]}
                            helperText={(errors[`escolaridade_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => handleChange(e.target.value, `escolaridade_${index}`)}
                          >
                            {getOptionsFromDominio("escolaridade").map((option: any, i: any) => (
                              <MenuItem key={option.id + i} value={option.id}>
                                {option.descricao}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            type="text"
                            variant="filled"
                            label="E-mail"
                            sx={{
                              width: ["100%", "100%", "100%", "59%"],
                            }}
                            {...register(`email_${index}`)}
                            error={!!errors[`email_${index}`]}
                            helperText={(errors[`email_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => {
                              validatorEmail(e.target.value, `email_${index}`);
                              handleChange(e.target.value, `email_${index}`);
                            }}
                          />
                        </Box>
                      </div>
                    ) : null}
                    {!showAlternateForm && form.id > 0 ? (
                      <div>
                        <Typography variant="h5" gutterBottom>
                          Dependentes
                        </Typography>
                        <Divider sx={{ margin: "0.5rem 0" }} />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: "1rem",
                            marginBottom: "1rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <TextField
                            type="text"
                            variant="filled"
                            label="Nome"
                            sx={{
                              width: ["100%", "100%", "100%", "60.5%"],
                            }}
                            {...register(`nome_razao_social_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`nome_razao_social_${index}`]}
                            helperText={(errors[`nome_razao_social_${index}`] as FieldError)?.message || ""}
                            inputProps={{ maxLength: 100 }}
                            onChange={(e) => handleChange(e.target.value, `nome_razao_social_${index}`)}
                          />
                          <TextField
                            type="text"
                            variant="filled"
                            label="CPF/CNPJ"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`cpf_cnpj_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`cpf_cnpj_${index}`]}
                            helperText={(errors[`cpf_cnpj_${index}`] as FieldError)?.message || ""}
                            inputProps={{ maxLength: 14 }}
                            onChangeCapture={handleChangeCpfCnpj}
                            onChange={(e) => {
                              validatorCpjCnpj(e.target.value, `cpf_cnpj_${index}`);
                              handleChange(e.target.value, `cpf_cnpj_${index}`);
                            }}
                          />
                          <DatePicker
                            label="Data de Nascimento"
                            disableFuture
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                              backgroundColor: "#F0F0F0",
                            }}
                            slotProps={{
                              layout: {},
                            }}
                            format="DD/MM/YYYY"
                            onChange={(e) => handleChange(e, `data_nascimento_${index}`, true)}
                          />
                          <TextField
                            select
                            variant="filled"
                            label="Grau de Parentesco"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`grau_parentesco_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`grau_parentesco_${index}`]}
                            helperText={(errors[`grau_parentesco_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => handleChange(Number(e.target.value), `grau_parentesco_${index}`)}
                          >
                            {getOptionsFromDominio("grau_parentesco").map((option: any, i: any) => (
                              <MenuItem key={option.codigo + i} value={option.codigo}>
                                {option.descricao}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            select
                            variant="filled"
                            label="Sexo"
                            sx={{
                              width: ["100%", "100%", "100%", "18%"],
                            }}
                            {...register(`sexo_${index}`, { required: "Campo obrigatório" })}
                            error={!!errors[`sexo_${index}`]}
                            helperText={(errors[`sexo_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => handleChange(e.target.value, `sexo_${index}`)}
                          >
                            {getOptionsFromDominio("sexo").map((option: any, i: any) => (
                              <MenuItem key={option.id + i} value={option.id}>
                                {option.descricao}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            type="text"
                            variant="filled"
                            label="E-mail"
                            sx={{
                              width: ["100%", "100%", "100%", "59%"],
                            }}
                            {...register(`email_${index}`)}
                            error={!!errors[`email_${index}`]}
                            helperText={(errors[`email_${index}`] as FieldError)?.message || ""}
                            onChange={(e) => {
                              validatorEmail(e.target.value, `email_${index}`);
                              handleChange(e.target.value, `email_${index}`);
                            }}
                          />
                        </Box>
                      </div>
                    ) : null}
                  </div>
                ))}
                {!showAlternateForm ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "15px",
                      width: ["100%", "100%", "30%", "18%"],
                    }}
                  >
                    <Button
                      startIcon={<FaPlusCircle />}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "100%",
                      }}
                      onClick={adicionarNovoFormulario}
                    >
                      Adicionar dependente
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "15px",
                      width: ["100%", "100%", "30%", "18%"],
                    }}
                  >
                    <Button
                      startIcon={<FaPlusCircle />}
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "100%",
                      }}
                      onClick={adicionarNovoFormulario}
                    >
                      Adicionar contato ou sócio
                    </Button>
                  </Box>
                )}
              </Box>

              <Box sx={{ width: "85%", display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    padding: "0.8rem",
                    width: "236px",
                  }}
                  disabled={!isValid}
                >
                  {!isLoading ? "Continuar" : "Salvando dados..."}
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" sx={{ color: "white" }} gutterBottom>
                  Desenvolvido por
                </Typography>

                <Box
                  component={"img"}
                  alt="LogoTopocart"
                  src={logoTopocart}
                  sx={{ height: 30, paddingLeft: "0.5rem" }}
                />
              </Box>
            </Box>
          </LocalizationProvider>
        </form>
      )}
    </>
  );
}
