import { Box, Button, Divider, MenuItem, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import cnpj from "validation-br/dist/cnpj";
import cpf from "validation-br/dist/cpf";
import background from "../../assets/background.jpg";
import DesenvolvidoPor from "../../components/DesenvolvidoPor";
import { Dominio } from "../../interfaces";
import { IlogInsert, IlogUpdate, tipoCampo } from "../../interfaces/logs.interface";
import { getAllDominios, getByUserByToken, postEmail, postUnidadeParcelar } from "../../services/api";
import { ufEstados } from "../../utils/ufs";
import { logInsert, logUpdate } from "../../services/logs";
import { v4 as uuid } from "uuid";
import { IUser } from "../../interfaces/user.interface";
import { getTypeField } from "../../utils/getTypeField";


interface LocationState {
  isCreate: boolean;
  fidUnidadeParcelar: string;
}

export function CriacaoUnidade() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();
  const location = useLocation<LocationState>();
  const fidUnidadeParcelar = location.state?.fidUnidadeParcelar;
  const [isLoading, setIsLoading] = useState(false);
  const [dominios, setDominios] = useState<Dominio[]>([]);
  const [showProprietarioForm, setShowProprietarioForm] = useState(false);
  const [dataUser, setDataUser] = useState({} as IUser);
  const history = useHistory();

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await getAllDominios();
      const dadosPessoais = await getByUserByToken();

      setDataUser(dadosPessoais.data);
      setDominios(response.data);
    } catch (error) {
      console.error("Ocorreu um erro ao baixar os dominions! ", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData().then();
  }, []);

  async function onSubmit() {
    setIsLoading(true);
    // handleFieldChange("atualizada_em", new Date(), "unidade_parcelar");

    const dados = JSON.parse(localStorage.getItem("log")!);
    const unidadeParcelarPessoa = dados.updates.find((log: IlogUpdate) => log.nome_tabela === "unidade_parcelar_pessoaUser" && log.nome_campo === "tipo_relacao");
    const updatePessoaEmail = dados.updates.find(
      (log: { nome_tabela: string; nome_campo: string }) =>
        log.nome_tabela === "pessoa" && log.nome_campo === "email"
    );

    dados.updates = dados.updates.map((log: any) => {
      if (log.nome_tabela === "cartorioRegistro") {
        log.nome_tabela = "cartorio";
      }
      if (log.nome_tabela === "unidade_parcelar_pessoaUser") {
        log.nome_tabela = "unidade_parcelar_pessoa";
      }
      return log;
    });

    dados.inserts = dados.inserts.map((log: any) => {
      if (log.nome_tabela === "cartorioRegistro") {
        log.nome_tabela = "cartorio";
      }
      if (log.nome_tabela === "unidade_parcelar_pessoaUser") {
        log.nome_tabela = "unidade_parcelar_pessoa";
      }
      return log;
    });

    if (unidadeParcelarPessoa) {
      dados.inserts = dados.inserts.map((log: IlogInsert) => {
        if (log.nome_tabela === "unidade_parcelar_pessoa") {
          log.fid = unidadeParcelarPessoa.id_origem;
        }
        return log;
      });

      dados.updates = dados.updates.map((log: IlogUpdate) => {
        if (log.nome_tabela === "unidade_parcelar_pessoa") {
          log.id_origem = unidadeParcelarPessoa.id_origem;
        }
        return log;
      });
    }

    dados.inserts = Array.from(
      new Map(
        dados.inserts.map((item: IlogInsert ) => [
          `${item.nome_tabela}-${item.fid}`,
          item
        ])
      ).values()
    );

    dados.updates = Array.from(
      new Map(
        dados.updates.map((item: { [key: string]: any }) => [
          `${item.nome_tabela}-${item.id_origem}-${item.valor_campo}-${item.nome_campo}-${item.tipo_campo}`,
          item
        ])
      ).values()
    );

    const protocolo = await postUnidadeParcelar(dados);

    await postEmail({
      assunto: "Unidade parcelar Criada",
      corpo: ` Prezado usuário, recebemos sua solicitação às ${new Date().toLocaleString()} e agradecemos pela criação cadastral da unidade parcelar. Os dados informados serão enviados para análise e incrementados à base da CODEVASF. O número do seu protocolo é ${
        protocolo.data.numero_protocolo
      }.`,
      destinatarios: [updatePessoaEmail ? updatePessoaEmail.valor_campo : dataUser.email]
    });

    if (protocolo.data.numero_protocolo) {
      history.push({
        pathname: "/protocolo",
        state: { userData: protocolo.data }
      });
    } else {
      toast.error("Não foi possível enviar protocolo");
    }
    setIsLoading(false);
  }

  function validatorCpjCnpj(value: string) {
    const isCnpjValid = cnpj(value);
    const isCpfValid = cpf(value);

    if (!isCpfValid && !isCnpjValid) {
      toast.error("CPF/CNPJ inválido");
      setIsLoading(false);
      return;
    } else {
    }
  }

  function getOptionsFromDominio(tabela: string) {
    const dominio = dominios?.find((item) => item.tabela === tabela);
    return dominio ? dominio.dominio : [];
  }

  function createIdOrigem(nome_tabela: string) {
    let newIdOrigem = {} as any;

    if (nome_tabela === "cartorioRegistro" || nome_tabela === "cartorio") {
      const fidCartorio = uuid();

      logInsert({
        nome_tabela: nome_tabela,
        fid: fidCartorio
      });

      logUpdate({
        nome_campo: nome_tabela === "cartorioRegistro" ? "registro_cartorio" : "escritura_cartorio",
        nome_tabela: "unidade_parcelar",
        tipo_campo: "numeric",
        id_origem: fidUnidadeParcelar,
        valor_campo: fidCartorio
      });

      newIdOrigem = { fid: fidCartorio };
    } else if (nome_tabela === "unidade_parcelar_pessoa") {
      const log = JSON.parse(localStorage.getItem("log")!);
      const fidUnidadeParcelarPessoa = uuid();
      const tipoRelacao = log.updates.find((log: IlogUpdate) => log.nome_campo === "tipo_relacao");
      const fidPessoa = tipoRelacao && tipoRelacao.valor_campo === 6 ? dataUser.id_pessoa : uuid();

      !(tipoRelacao && tipoRelacao.valor_campo === 6) && logInsert({
        nome_tabela: "pessoa",
        fid: String(fidPessoa)
      });

      logInsert({
        nome_tabela: nome_tabela,
        fid: fidUnidadeParcelarPessoa
      });

      logUpdate({
        nome_campo: "id_unidade_parcelar",
        nome_tabela: nome_tabela,
        tipo_campo: "numeric",
        id_origem: fidUnidadeParcelarPessoa,
        valor_campo: fidUnidadeParcelar
      });

      logUpdate({
        nome_campo: "id_pessoa",
        nome_tabela: nome_tabela,
        tipo_campo: "numeric",
        id_origem: fidUnidadeParcelarPessoa,
        valor_campo: fidPessoa
      });

      newIdOrigem = { fid: fidUnidadeParcelarPessoa };
    } else if (nome_tabela === "pessoa") {
      const fidPessoa = uuid();

      logInsert({
        nome_tabela: "pessoa",
        fid: fidPessoa
      });

      const log = JSON.parse(localStorage.getItem("log")!);

      log.updates = log.updates.map((logItem: IlogUpdate) => {
        if (logItem.nome_tabela === "unidade_parcelar_pessoa" && logItem.nome_campo === "id_pessoa") {
          logItem.valor_campo = fidPessoa;
        }
        return logItem;
      });

      localStorage.setItem("log", JSON.stringify(log));
      newIdOrigem = { fid: fidPessoa };
    }
    return newIdOrigem.fid;
  }

  function getIdOrigem(nome_tabela: string, valor: string | number, nomeCampo: string) {
    const logs = JSON.parse(localStorage.getItem("log")!);
    let log = logs.inserts.find((log: IlogInsert) => log.nome_tabela === nome_tabela);

    if (!log) {
      log = {};
      log.fid = createIdOrigem(nome_tabela);
    }

    if (nomeCampo === "tipo_relacao") {
      clearProprietarioForm(nome_tabela, Number(valor));
    }

    return log.fid;
  }

  function clearProprietarioForm(nome_tabela: string, valor: number) {
    const inputNames = [
      "nome_razao_social",
      "cpf_cnpj",
      "rg",
      "orgao_emissor_uf",
      "data_nascimento",
      "sexo",
      "estado_civil",
      "escolaridade",
      "email",
      "ddd_celular",
      "telefone_celular",
      "tipo_endereco",
      "logradouro",
      "bairro",
      "numero",
      "uf",
      "cep",
      "cidade"
    ];
    const logs = JSON.parse(localStorage.getItem("log")!);
    const fidUppU = logs.inserts.find((log: IlogInsert) => log.nome_tabela === "unidade_parcelar_pessoaUser")?.fid;
    const fidUpp = logs.inserts.find((log: IlogInsert) => log.nome_tabela === "unidade_parcelar_pessoa")?.fid;
    const fidPessoa = logs.inserts.find((log: IlogInsert) => log.nome_tabela === "pessoa")?.fid;

    if (fidUpp && valor !== 6) {
      logs.updates = logs.updates.map((log: IlogUpdate) => {
        if (log.nome_tabela === "unidade_parcelar_pessoa") {
          log.id_origem = fidUpp;
        }
        return log;
      });

      logs.updates = logs.updates.map((log: IlogUpdate) => {
        if (log.nome_tabela === "unidade_parcelar_pessoa" && log.nome_campo === "id_pessoa") {
          log.valor_campo = fidPessoa ? fidPessoa : log.valor_campo;
        }
        return log;
      });
    }

    if (nome_tabela === "unidade_parcelar_pessoaUser" && valor === 6) {
      inputNames.forEach((inputName) => {
        setValue(inputName, "");
      });

      logs.updates = logs.updates.map((log: IlogUpdate) => {
        if (log.nome_tabela === "unidade_parcelar_pessoa") {
          log.id_origem = fidUppU;
        }
        return log;
      });

      logs.updates = logs.updates.map((log: IlogUpdate) => {
        if (log.nome_tabela === "unidade_parcelar_pessoa" && log.nome_campo === "id_pessoa") {
          log.valor_campo = dataUser.id_pessoa;
        }
        return log;
      });

    }
    localStorage.setItem("log", JSON.stringify(logs));
  }

  function handleFieldChange(nomeCampo: any, valor: any, nome_tabela: string) {
    const tipo_campo: tipoCampo = getTypeField(typeof valor) as tipoCampo;
    const idOrigem = nome_tabela !== "unidade_parcelar" ? getIdOrigem(nome_tabela, valor, nomeCampo) : fidUnidadeParcelar;

    logUpdate({
      nome_tabela: nome_tabela,
      id_origem: idOrigem,
      nome_campo: nomeCampo,
      tipo_campo: tipo_campo,
      valor_campo: valor
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: `url(${background})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              paddingBottom: "20px"
            }}
          >
            <Box
              sx={{
                width: "85%",
                background: "#FFFFFF",
                height: "85%",
                borderRadius: "5px",
                marginTop: "30px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll"
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}
                gutterBottom
              >
                Criação de Nova Unidade Parcelar
              </Typography>
              <Divider sx={{ margin: "20px 0", marginTop: "30px" }} />

              <Typography variant="h5" gutterBottom>
                Identificação da Unidade Parcelar
              </Typography>
              <Divider sx={{ margin: "0.5rem 0" }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  label="Tipo de Unidade Parcelar"
                  select
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("tipo_up", { required: "Este campo é obrigatório" })}
                  error={!!errors.tipo_up}
                  helperText={(errors.tipo_up as FieldError)?.message || ""}
                  onChange={(e) => handleFieldChange("tipo_up", e.target.value, "unidade_parcelar")}
                  defaultValue={""}
                >
                  {getOptionsFromDominio("tipo_up").map((option: any, i: number) => (
                    <MenuItem key={option.id + i} value={option.id}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Superintendência"
                  select
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("superintendencia", { required: "Este campo é obrigatório" })}
                  error={!!errors.superintendencia}
                  helperText={(errors.superintendencia as FieldError)?.message || ""}
                  onChange={(e: any) => handleFieldChange("superintendencia", e.target.value, "unidade_parcelar")}
                  defaultValue={""}
                >
                  {getOptionsFromDominio("superintendencia").map((option: any, i: any) => (
                    <MenuItem key={option.id + i} value={option.id}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Perímetro"
                  select
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("perimetro", { required: "Este campo é obrigatório" })}
                  error={!!errors.perimetro}
                  helperText={(errors.perimetro as FieldError)?.message || ""}
                  onChange={(e: any) => handleFieldChange("perimetro", e.target.value, "unidade_parcelar")}
                  defaultValue={""}
                >
                  {getOptionsFromDominio("perimetro").map((option: any, i: number) => (
                    <MenuItem key={option.id + i} value={option.id}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Etapa"
                  select
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("etapa", { required: "Este campo é obrigatório" })}
                  error={!!errors.etapa}
                  helperText={(errors.etapa as FieldError)?.message || ""}
                  onChange={(e: any) => handleFieldChange("etapa", e.target.value, "unidade_parcelar")}
                  defaultValue={""}
                >
                  {getOptionsFromDominio("etapa").map((option: any, i: number) => (
                    <MenuItem key={option.id + i} value={option.id}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Identificador da UP"
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("identificador_up", { required: "Este campo é obrigatório" })}
                  error={!!errors.identificador_up}
                  helperText={(errors.identificador_up as FieldError)?.message || ""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("identificador_up", e.target.value, "unidade_parcelar")
                  }
                  defaultValue={""}
                ></TextField>
                <TextField
                  label="Car"
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "32%", "32%"]
                  }}
                  {...register("car", { required: false })}
                  error={!!errors.car}
                  helperText={(errors.car as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("car", e.target.value, "unidade_parcelar")}
                ></TextField>
                <TextField
                  label="NIRF (CIB)"
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "32%", "32%"]
                  }}
                  {...register("nirf", { required: false })}
                  error={!!errors.nirf}
                  helperText={(errors.nirf as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("nirf", e.target.value, "unidade_parcelar")}
                ></TextField>
                <TextField
                  label="Código do Incra(CCIR)"
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "32%", "32%"]
                  }}
                  {...register("cod_incra_ccir", { required: false })}
                  error={!!errors.cod_incra_ccir}
                  helperText={(errors.cod_incra_ccir as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("cod_incra_ccir", e.target.value, "unidade_parcelar")
                  }
                ></TextField>
              </Box>

              <Typography variant="h5" gutterBottom>
                Características Físicas
              </Typography>
              <Divider sx={{ margin: "0.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  label="Área Não Irrigável (ha)"
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("area_nao_irrigavel_ha", { required: "Este campo é obrigatório" })}
                  error={!!errors.area_nao_irrigavel_ha}
                  helperText={(errors.area_nao_irrigavel_ha as FieldError)?.message || ""}
                  onChange={(e) => handleFieldChange("area_nao_irrigavel_ha", e.target.value, "unidade_parcelar")}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("area_nao_irrigavel_ha", e.target.value, "unidade_parcelar")
                  }
                ></TextField>
                <TextField
                  label="Área Irrigável (ha)"
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("area_irrigavel_ha", { required: "Este campo é obrigatório" })}
                  error={!!errors.area_irrigavel_ha}
                  helperText={(errors.area_irrigavel_ha as FieldError)?.message || ""}
                  onChange={(e) => handleFieldChange("area_irrigavel_ha", e.target.value, "unidade_parcelar")}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("area_irrigavel_ha", e.target.value, "unidade_parcelar")
                  }
                ></TextField>
                <TextField
                  label="Área Total (ha)"
                  variant="filled"
                  sx={{
                    width: ["100%", "100%", "18%", "18%"]
                  }}
                  {...register("area_total_ha", { required: "Este campo é obrigatório" })}
                  error={!!errors.area_total_ha}
                  helperText={(errors.area_total_ha as FieldError)?.message || ""}
                  onChange={(e) => handleFieldChange("area_total_ha", e.target.value, "unidade_parcelar")}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("area_total_ha", e.target.value, "unidade_parcelar")}
                ></TextField>
              </Box>
              <Typography variant="h5" gutterBottom>
                Qual a sua relação com a Unidade Parcelar ?
              </Typography>
              <Divider sx={{ margin: "0.5rem 0" }} />
              <TextField
                select
                label="Relação com a UP"
                variant="filled"
                sx={{
                  width: "100%"
                }}
                {...register("tipo_relacao", { required: "Este campo é obrigatório" })}
                error={!!errors.tipo_relacao}
                helperText={(errors.tipo_relacao as FieldError)?.message || ""}
                defaultValue={""}
                onChange={(e) => {
                  handleFieldChange("tipo_relacao", e.target.value, "unidade_parcelar_pessoaUser");
                  if (Number(e.target.value) !== 6) {
                    setShowProprietarioForm(true);
                  } else {
                    setShowProprietarioForm(false);
                  }
                }}
              >
                {getOptionsFromDominio("tipo_relacao").map((option: any, i: any) => (
                  <MenuItem key={option.id + i} value={option.id}>
                    {option.descricao}
                  </MenuItem>
                ))}
              </TextField>
              {showProprietarioForm && (
                <>
                  <Typography variant="h5" sx={{ marginTop: "1rem " }} gutterBottom>
                    Dados do Proprietário
                  </Typography>
                  <Divider sx={{ margin: "0.5rem 0" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap"
                    }}
                  >
                    <TextField
                      type="text"
                      variant="filled"
                      label="Nome/ Razão Social"
                      sx={{
                        width: ["100%", "100%", "100%", "80%"]
                      }}
                      {...register("nome_razao_social", { required: "Este campo é obrigatório" })}
                      error={!!errors.nome_razao_social}
                      helperText={(errors.nome_razao_social as FieldError)?.message || ""}
                      onChange={(e) => handleFieldChange("nome_razao_social", e.target.value, "pessoa")}
                      defaultValue={""}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="CPF/CNPJ"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"]
                      }}
                      {...register("cpf_cnpj", { required: "Este campo é obrigatório" })}
                      error={!!errors.cpf_cnpj}
                      helperText={(errors.cpf_cnpj as FieldError)?.message || ""}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("cpf_cnpj", e.target.value, "pessoa")}
                      inputProps={{ maxLength: 14 }}
                      onChange={(e) => validatorCpjCnpj(e.target.value)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap"
                    }}
                  >
                    <TextField
                      type="text"
                      variant="filled"
                      label="RG"
                      sx={{
                        width: ["100%", "100%", "18%", "18%"]
                      }}
                      {...register("rg", { required: "Este campo é obrigatório" })}
                      error={!!errors.rg}
                      helperText={(errors.rg as FieldError)?.message || ""}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("rg", e.target.value, "pessoa")}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="Orgão Emissor/UF"
                      sx={{
                        width: ["100%", "100%", "18%", "19%"]
                      }}
                      {...register("orgao_emissor_uf", { required: "Este campo é obrigatório" })}
                      error={!!errors.orgao_emissor_uf}
                      helperText={(errors.orgao_emissor_uf as FieldError)?.message || ""}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("orgao_emissor_uf", e.target.value, "pessoa")}
                    />
                    <DatePicker
                      label="Data de Nascimento"
                      disableFuture
                      sx={{
                        width: ["100%", "100%", "18.2%", "19%"],
                        backgroundColor: "#F0F0F0"
                      }}
                      slotProps={{
                        layout: {}
                      }}
                      {...register("data_nascimento", { required: "Este campo é obrigatório" })}
                      // error={!!errors.data_nascimento}
                      // helperText={(errors.data_nascimento as FieldError)?.message || ""}
                      //@ts-ignore
                      defaultValue={null}
                      format="DD/MM/YYYY"
                      onChange={(date: Date | null) => handleFieldChange("data_nascimento", date, "pessoa")}
                    />
                    <TextField
                      select
                      variant="filled"
                      label="Sexo"
                      sx={{
                        width: ["100%", "100%", "19%", "19%"]
                      }}
                      {...register("sexo", { required: "Este campo é obrigatório" })}
                      error={!!errors.sexo}
                      helperText={(errors.sexo as FieldError)?.message || ""}
                      onChange={(e: any) => handleFieldChange("sexo", e.target.value, "pessoa")}
                      defaultValue={""}
                    >
                      {getOptionsFromDominio("sexo").map((option: any, i: any) => (
                        <MenuItem key={option.id + i} value={option.id}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      variant="filled"
                      label="Estado Cívil"
                      sx={{
                        width: ["100%", "100%", "100%", "19%"]
                      }}
                      {...register("estado_civil", { required: "Este campo é obrigatório" })}
                      error={!!errors.estado_civil}
                      helperText={(errors.estado_civil as FieldError)?.message || ""}
                      defaultValue={""}
                      onChange={(e: any) => handleFieldChange("estado_civil", e.target.value, "pessoa")}
                    >
                      {getOptionsFromDominio("estado_civil").map((option: any, i: any) => (
                        <MenuItem key={option.id + i} value={option.id}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap"
                    }}
                  >
                    <TextField
                      select
                      variant="filled"
                      label="Escolaridade"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"]
                      }}
                      {...register("escolaridade", { required: "Este campo é obrigatório" })}
                      error={!!errors.escolaridade}
                      helperText={(errors.escolaridade as FieldError)?.message || ""}
                      defaultValue={""}
                      onChange={(e: any) => handleFieldChange("escolaridade", e.target.value, "pessoa")}
                    >
                      {getOptionsFromDominio("escolaridade").map((option: any, i: any) => (
                        <MenuItem key={option.id + i} value={option.id}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  <Typography variant="h5" sx={{ marginTop: "1rem " }} gutterBottom>
                    Endereço de Correspondência do Proprietário
                  </Typography>
                  <Divider sx={{ margin: "0.5rem 0" }} />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap"
                    }}
                  >
                    <TextField
                      select
                      variant="filled"
                      label="Tipo de Endereço"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"]
                      }}
                      {...register("tipo_endereco", { required: "Este campo é obrigatório" })}
                      error={!!errors.tipo_endereco}
                      helperText={(errors.tipo_endereco as FieldError)?.message || ""}
                      defaultValue={""}
                      onChange={(e: any) => handleFieldChange("tipo_endereco", e.target.value, "pessoa")}
                    >
                      {getOptionsFromDominio("tipo_endereco").map((option: any, i: any) => (
                        <MenuItem key={option.id + i} value={option.id}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      type="text"
                      variant="filled"
                      label="Logradouro"
                      sx={{
                        width: ["100%", "100%", "100%", "80%"]
                      }}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("logradouro", e.target.value, "pessoa")}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap"
                    }}
                  >
                    <TextField
                      type="number"
                      variant="filled"
                      label="Número/Complemento"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"]
                      }}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("numero", e.target.value, "pessoa")}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="Bairro"
                      sx={{
                        width: ["100%", "100%", "100%", "28%"]
                      }}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("bairro", e.target.value, "pessoa")}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="Cidade"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"]
                      }}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("cidade", e.target.value, "pessoa")}
                    />
                    <TextField
                      select
                      variant="filled"
                      label="UF"
                      sx={{
                        width: ["100%", "100%", "100%", "10%"]
                      }}
                      defaultValue={""}
                      {...register("uf")}
                      error={!!errors.uf}
                      helperText={(errors.uf as FieldError)?.message || ""}
                      inputProps={{ maxLength: 200 }}
                      onChangeCapture={(e: any) => handleFieldChange("uf", e.target.value, "pessoa")}
                    >
                      {ufEstados.map((uf: string, i: any) => (
                        <MenuItem key={i} value={uf}>
                          {uf}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      type="text"
                      variant="filled"
                      label="CEP"
                      sx={{
                        width: ["100%", "100%", "100%", "20%"]
                      }}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("cep", e.target.value, "pessoa")}
                      inputProps={{ maxLength: 8 }}
                    />
                  </Box>
                  <Typography variant="h5" gutterBottom>
                    Contato
                  </Typography>
                  <Divider sx={{ margin: "0.5rem 0" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "1rem",
                      flexWrap: "wrap"
                    }}
                  >
                    <TextField
                      type="text"
                      variant="filled"
                      label="DDD"
                      sx={{
                        width: ["100%", "100%", "100%", "18%"]
                      }}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("ddd_celular", e.target.value, "pessoa")}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="Telefone Celular"
                      sx={{
                        width: ["100%", "100%", "100%", "25%"]
                      }}
                      onChangeCapture={(e: any) => handleFieldChange("telefone_celular", e.target.value, "pessoa")}
                      defaultValue={""}
                    />
                    <TextField
                      type="text"
                      variant="filled"
                      label="E-mail"
                      sx={{
                        width: ["100%", "100%", "100%", "53%"]
                      }}
                      defaultValue={""}
                      onChangeCapture={(e: any) => handleFieldChange("email", e.target.value, "pessoa")}
                    />
                  </Box>
                </>
              )}

              <Typography variant="h5" sx={{ marginTop: "1rem" }} gutterBottom>
                Características Físicas
              </Typography>

              <Divider sx={{ margin: "0.5rem 0" }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  select
                  variant="filled"
                  label="Finalidade"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("id_tipo_finalidade_lote_codevasf", { required: false })}
                  error={!!errors.id_tipo_finalidade_lote_codevasf}
                  helperText={(errors.id_tipo_finalidade_lote_codevasf as FieldError)?.message || ""}
                  defaultValue={""}
                  onChange={(e: any) =>
                    handleFieldChange("id_tipo_finalidade_lote_codevasf", e.target.value, "unidade_parcelar")
                  }
                >
                  {getOptionsFromDominio("tipo_finalidade").map((option: any, i: any) => (
                    <MenuItem key={option.id + i} value={option.id_tipo_finalidade_lote_codevasf}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>

                <DatePicker
                  label="Início da Ocupação"
                  disableFuture
                  sx={{
                    width: ["100%", "100%", "100%", "18%"],
                    backgroundColor: "#F0F0F0"
                  }}
                  slotProps={{
                    layout: {}
                  }}
                  {...register("data_inicio", { required: false })}
                  // error={!!errors.data_inicio}
                  // helperText={(errors.data_inicio as FieldError)?.message || ""}
                  //@ts-ignore
                  defaultValue={null}
                  format="DD/MM/YYYY"
                  onChange={(date: Date | null) => handleFieldChange("data_inicio", date, "unidade_parcelar_pessoa")}
                />

                <TextField
                  select
                  variant="filled"
                  label="Forma de Ocupação"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("forma_ocupacao", { required: false })}
                  error={!!errors.forma_ocupacao}
                  helperText={(errors.forma_ocupacao as FieldError)?.message || ""}
                  defaultValue={""}
                  onChange={(e: any) =>
                    handleFieldChange("forma_ocupacao", e.target.value, "unidade_parcelar_pessoa")
                  }
                >
                  {getOptionsFromDominio("forma_ocupacao").map((option: any, i: any) => (
                    <MenuItem key={option.id + i} value={option.id}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  type="text"
                  variant="filled"
                  label="Tipo de Ato Normativo"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("tipo_ato_normativo", { required: false })}
                  error={!!errors.tipo_ato_normativo}
                  helperText={(errors.tipo_ato_normativo as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("tipo_ato_normativo", e.target.value, "unidade_parcelar_pessoa")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="N° Ano do Ato Normativo"
                  {...register("ato_ano", { required: false })}
                  error={!!errors.ato_ano}
                  helperText={(errors.ato_ano as FieldError)?.message || ""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("ato_ano", e.target.value, "unidade_parcelar_pessoa")
                  }
                  defaultValue={""}
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Unidade Emissorra"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("ato_unidade_eminente", { required: false })}
                  error={!!errors.ato_unidade_eminente}
                  helperText={(errors.ato_unidade_eminente as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("ato_unidade_emitente", e.target.value, "unidade_parcelar_pessoa")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Valor da Aquisição"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("valor_aquisicao", { required: false })}
                  error={!!errors.valor_aquisicao}
                  helperText={(errors.valor_aquisicao as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("valor_aquisicao", e.target.value, "unidade_parcelar")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Número de Parcelas"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("numero_parcelas", { required: false })}
                  error={!!errors.numero_parcelas}
                  helperText={(errors.numero_parcelas as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("numero_parcelas", e.target.value, "unidade_parcelar")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Carência de Titulação"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("carencia_titulacao", { required: false })}
                  error={!!errors.carencia_titulacao}
                  helperText={(errors.carencia_titulacao as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("carencia_titulacao", e.target.value, "unidade_parcelar")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Carência de K1"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  {...register("carencia_k1", { required: false })}
                  error={!!errors.carencia_k1}
                  helperText={(errors.carencia_k1 as FieldError)?.message || ""}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("carencia_k1", e.target.value, "unidade_parcelar")}
                />
              </Box>

              <TextField
                type="text"
                variant="filled"
                label="Valor Total"
                sx={{
                  width: ["100%", "100%", "100%", "18%"]
                }}
                {...register("valor_total", { required: false })}
                error={!!errors.valor_total}
                helperText={(errors.valor_total as FieldError)?.message || ""}
                defaultValue={""}
                onChangeCapture={(e: any) => handleFieldChange("valor_total", e.target.value, "unidade_parcelar")}
              />

              <Typography variant="h5" sx={{ marginTop: "1rem" }} gutterBottom>
                Escritura - Cartório de Notas
              </Typography>

              <Divider sx={{ margin: "0.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Cartório"
                  sx={{
                    width: ["100%", "100%", "100%", "100%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("nome", e.target.value, "cartorio")}
                />

                <DatePicker
                  label="Data"
                  disableFuture
                  sx={{
                    width: ["100%", "100%", "100%", "19%"],
                    backgroundColor: "#F0F0F0"
                  }}
                  slotProps={{
                    layout: {}
                  }}
                  //@ts-ignore
                  defaultValue={null}
                  format="DD/MM/YYYY"
                  onChange={(date: Date | null) => handleFieldChange("escritura_data", date, "unidade_parcelar")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Livro"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("escritura_livro", e.target.value, "unidade_parcelar")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Folha"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("escritura_folha", e.target.value, "unidade_parcelar")
                  }
                />
                {/* <TextField
                    type="text"
                    variant="filled"
                    label="Matrícula"
                    sx={{
                      width: ["100%", "100%", "100%", "18%"],
                    }}
                    defaultValue={dadosEdicaoUnidade?.unidade_parcelar[0]?.escritura_matricula || ""}
                    onChangeCapture={(e: any) =>
                      handleFieldChange("escritura_matricula", e.target.value, "unidade_parcelar")
                    }
                  /> */}
              </Box>

              <Typography variant="h5" sx={{ marginTop: "1rem" }} gutterBottom>
                Endereço do Cartório de Notas
              </Typography>

              <Divider sx={{ margin: "0.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Logradouro"
                  sx={{
                    width: ["100%", "100%", "100%", "40%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("logradouro", e.target.value, "cartorio")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Bairro"
                  sx={{
                    width: ["100%", "100%", "100%", "28%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("bairro", e.target.value, "cartorio")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="CEP"
                  sx={{
                    width: ["100%", "100%", "100%", "28%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("cep", e.target.value, "cartorio")}
                  inputProps={{ maxLength: 8 }}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="E-mail"
                  sx={{
                    width: ["100%", "100%", "100%", "40%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("email", e.target.value, "cartorio")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Site"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("site", e.target.value, "cartorio")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Telefone Fixo"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("telefone_fixo", e.target.value, "cartorio")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Telefone Celular"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("telefone_celular", e.target.value, "cartorio")}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Cidade"
                  sx={{
                    width: ["100%", "100%", "100%", "19%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("cidade", e.target.value, "cartorio")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="UF"
                  sx={{
                    width: ["100%", "100%", "100%", "19%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("uf", e.target.value, "cartorio")}
                />
              </Box>

              <Typography variant="h5" sx={{ marginTop: "1rem" }} gutterBottom>
                Registro - Cartório de Registro de Imóveis
              </Typography>

              <Divider sx={{ margin: "0.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Cartório"
                  sx={{
                    width: ["100%", "100%", "100%", "100%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("nome", e.target.value, "cartorioRegistro")}
                />

                <DatePicker
                  label="Data"
                  disableFuture
                  sx={{
                    width: ["100%", "100%", "100%", "19%"],
                    backgroundColor: "#F0F0F0"
                  }}
                  slotProps={{
                    layout: {}
                  }}
                  //@ts-ignore
                  defaultValue={null}
                  format="DD/MM/YYYY"
                  onChange={(date: Date | null) => handleFieldChange("registro_data", date, "unidade_parcelar")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Livro"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("registro_livro", e.target.value, "unidade_parcelar")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Folha"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("registro_folha", e.target.value, "unidade_parcelar")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Matrícula"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("registro_matricula", e.target.value, "unidade_parcelar")
                  }
                />
              </Box>

              <Typography variant="h5" sx={{ marginTop: "1rem" }} gutterBottom>
                Endereço do Cartório de Registro de Imóveis
              </Typography>

              <Divider sx={{ margin: "0.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Logradouro"
                  sx={{
                    width: ["100%", "100%", "100%", "40%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("logradouro", e.target.value, "cartorioRegistro")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Bairro"
                  sx={{
                    width: ["100%", "100%", "100%", "28%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("bairro", e.target.value, "cartorioRegistro")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="CEP"
                  sx={{
                    width: ["100%", "100%", "100%", "28%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("cep", e.target.value, "cartorioRegistro")}
                  inputProps={{ maxLength: 8 }}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="E-mail"
                  sx={{
                    width: ["100%", "100%", "100%", "40%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("email", e.target.value, "cartorioRegistro")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Site"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("site", e.target.value, "cartorioRegistro")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Telefone Fixo"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("telefone_fixo", e.target.value, "cartorioRegistro")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Telefone Celular"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("telefone_celular", e.target.value, "cartorioRegistro")}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Cidade"
                  sx={{
                    width: ["100%", "100%", "100%", "19%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("cidade", e.target.value, "cartorioRegistro")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="UF"
                  sx={{
                    width: ["100%", "100%", "100%", "19%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("uf", e.target.value, "cartorioRegistro")}
                />
              </Box>

              <Typography variant="h5" sx={{ marginTop: "1rem" }} gutterBottom>
                Concessão de Água
              </Typography>

              <Divider sx={{ margin: "0.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                  justifyContent: "space-between"
                }}
              >
                <TextField
                  select
                  variant="filled"
                  label="Tipo de Área"
                  sx={{
                    width: ["100%", "100%", "100%", "100%"]
                  }}
                  defaultValue={""}
                  onChange={(e: any) => handleFieldChange("tipo_area_concessao", e.target.value, "concessao_agua")}
                >
                  {getOptionsFromDominio("tipo_area_concessao").map((option: any, i: any) => (
                    <MenuItem key={option.id + i} value={option.id}>
                      {option.descricao}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap",
                  justifyContent: "space-between"
                }}
              >
                <DatePicker
                  label="Ínicio do Fornecimento"
                  disableFuture
                  sx={{
                    width: ["100%", "100%", "100%", "18%"],
                    backgroundColor: "#F0F0F0"
                  }}
                  slotProps={{
                    layout: {}
                  }}
                  //@ts-ignore
                  defaultValue={null}
                  format="DD/MM/YYYY"
                  onChange={(date: Date | null) => handleFieldChange("inicio_concessao", date, "concessao_agua")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Número do Processo"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("processo_numero", e.target.value, "concessao_agua")}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Quantidade de pessoas beneficiadas"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("qtde_pessoa_beneficiada", e.target.value, "concessao_agua")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Área de Concessão (ha)"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("concessao_agua_ha", e.target.value, "concessao_agua")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Vazão (l/s)"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) => handleFieldChange("vazao_l_s", e.target.value, "concessao_agua")}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  marginBottom: "1rem",
                  flexWrap: "wrap"
                }}
              >
                <TextField
                  type="text"
                  variant="filled"
                  label="Tipo de Ato Normativo"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  defaultValue={""}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("tipo_ato_normativo", e.target.value, "concessao_agua")
                  }
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="N° Ano do Ato Normativo"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  onChangeCapture={(e: any) => handleFieldChange("ato_numero", e.target.value, "concessao_agua")}
                  defaultValue={""}
                />

                <TextField
                  type="text"
                  variant="filled"
                  label="Unidade Emissorra"
                  sx={{
                    width: ["100%", "100%", "100%", "18%"]
                  }}
                  onChangeCapture={(e: any) =>
                    handleFieldChange("ato_orgao_emitente", e.target.value, "concessao_agua")
                  }
                  defaultValue={""}
                />
              </Box>
            </Box>

            <Box sx={{ width: "85%", display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  padding: "0.8rem",
                  width: "236px"
                }}
                disabled={isLoading}
              >
                {!isLoading ? "Finalizar" : "Finalizando..."}
              </Button>
            </Box>
            <DesenvolvidoPor />
          </Box>
        </LocalizationProvider>
      </form>
    </>
  );
}
