import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { Coordinate } from "ol/coordinate";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import background from "../../assets/background.jpg";
import DesenvolvidoPor from "../../components/DesenvolvidoPor";
import { Map } from "../../components/Maps";
import { UnidadeParcelar } from "../../interfaces";
import { logInsert, logUpdate } from "../../services/logs";
import { getByUserByToken } from "../../services/api";

interface LocationState {
  unidadeParcelar: UnidadeParcelar | undefined;
}

export default function LocalizaUnidade() {
  const [coordenadas, setCoordenadas] = useState<Coordinate>();
  const location = useLocation<LocationState>();
  const unidadeParcelar = location.state?.unidadeParcelar;

  const theme = useTheme();

  const history = useHistory();

  async function saveCoords(): Promise<string> {
    if (!coordenadas) throw new Error("Coordenadas não informadas");
    const dadosPessoais = await getByUserByToken();


    if (!unidadeParcelar) {
      const fid = uuid();
      const fidUpp =  uuid();
      const fidConcessao = uuid();

      logInsert({
        nome_tabela: "unidade_parcelar",
        fid: fid,
      });

      logInsert({
        nome_tabela: "unidade_parcelar_pessoaUser",
        fid: fidUpp,
      });

      logInsert({
        nome_tabela: "concessao_agua",
        fid: fidConcessao,
      });

      logUpdate({
        nome_campo: "id_unidade_parcelar",
        nome_tabela: "concessao_agua",
        tipo_campo: "numeric",
        id_origem: fidConcessao,
        valor_campo: fid,
      });

      logUpdate({
        nome_campo: "long",
        nome_tabela: "unidade_parcelar",
        tipo_campo: "numeric",
        id_origem: fid,
        valor_campo: coordenadas[0],
      });

      logUpdate({
        nome_campo: "lat",
        nome_tabela: "unidade_parcelar",
        tipo_campo: "numeric",
        id_origem: fid,
        valor_campo: coordenadas[1],
      });

      logUpdate({
        nome_campo: "id_pessoa",
        nome_tabela: "unidade_parcelar_pessoaUser",
        tipo_campo: "numeric",
        id_origem: fidUpp,
        valor_campo: dadosPessoais.data.id_pessoa
      });

      logUpdate({
        nome_campo: "id_unidade_parcelar",
        nome_tabela: "unidade_parcelar_pessoaUser",
        tipo_campo: "numeric",
        id_origem: fidUpp,
        valor_campo: fid
      });

      return fid;
    } else {
      logUpdate({
        nome_campo: "long",
        nome_tabela: "unidade_parcelar",
        tipo_campo: "numeric",
        id_origem: unidadeParcelar.id_unidade_parcelar,
        valor_campo: coordenadas[0],
      });
      logUpdate({
        nome_campo: "lat",
        nome_tabela: "unidade_parcelar",
        tipo_campo: "numeric",
        id_origem: unidadeParcelar.id_unidade_parcelar,
        valor_campo: coordenadas[1],
      });
      return String(unidadeParcelar.id_unidade_parcelar);
    }
  }

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          "@media (max-width: 1000px)": {
            minHeight: "100%",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            width: "90%",
            minHeight: "85%",
            background: "#FFFFFF",
            borderRadius: "5px",
            marginTop: "70px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            "@media (max-width: 1000px)": {
              width: "70%",
              height: "45%",
            },
            "@media (max-width: 600px)": {
              width: "90%",
              height: "60%",
            },
            "@media (max-width: 400px)": {
              width: "85%",
              height: "75%",
            },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            {unidadeParcelar
              ? "Indique a localização do seu lote no mapa"
              : "Indique a localização do lote que deseja cadastrar"}
          </Typography>
          <Divider />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Map setCoordenadas={setCoordenadas} unidadeSelecionada={unidadeParcelar} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "90%",
            display: "flex",
            justifyContent: "flex-end",
            gap: theme.spacing(1),
            "@media (max-width: 1000px)": {
              justifyContent: "center",
              marginBottom: theme.spacing(10),
            },
          }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
              padding: theme.spacing(0.8),
              width: "236px",
              maxWidth: "45%",
            }}
            onClick={() => history.push("/lista-unidades")}
          >
            Voltar
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
              padding: theme.spacing(0.8),
              width: "236px",
              maxWidth: "45%",
            }}
            disabled={!coordenadas}
            onClick={async () => {
              const idUnidadeParcelar = await saveCoords();
              if (unidadeParcelar) {
                history.push(`/edicao-unidade/${unidadeParcelar.id_lote}`);
              } else {
                history.push({
                  pathname: "/criacao-unidade",
                  state: { isCreate: true, fidUnidadeParcelar: idUnidadeParcelar },
                });
              }
            }}
          >
            Continuar
          </Button>
        </Box>
        <DesenvolvidoPor />
      </Box>
    </>
  );
}
