import { Box, Typography } from "@mui/material";
import logoTopocart from "../../assets/logo_topocart.png";

export default function DesenvolvidoPor() {

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "3rem",
        position: "fixed",
        bottom: 0,
        marginBottom: "1rem",
      }}
    >
      <Typography variant="h6" sx={{ color: "white", marginTop: "11px" }} gutterBottom>
        {process.env.REACT_APP_PRODUCTION === 'true' ? 'Desenvolvido por' : 'Em desenvolvimento por'}
      </Typography>
      <Box component={"img"} alt="LogoTopocart" src={logoTopocart} sx={{ height: 30, paddingLeft: "0.5rem" }} />
    </Box>
  );
}
